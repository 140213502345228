import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { cn } from '../../lib/helpers'
import { MdClose } from 'react-icons/md'
import VimeoPlayer from './VimeoPlayer'

// Looks like gatsby does not support lazy loading and suspense yet. This might be SSR related.
// const VimeoPlayer = React.lazy(() => import('./VimeoPlayer'))

import styles from './VideoPlayer.module.css'

export default class VideoPlayer extends Component {
  static propTypes = {
    opened: PropTypes.bool.isRequired,
    url: PropTypes.string,
    closeHandler: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={cn(styles.root, this.props.opened && styles.open)}>
        {this.props.url && (
          <VimeoPlayer
            videoUrl={this.props.url}
            closeVideoHandler={this.props.closeHandler}
            shouldBePlaying={this.props.opened}
          ></VimeoPlayer>
        )}

        <div className={styles.close} onClick={this.props.closeHandler}>
          <MdClose></MdClose>
        </div>
      </div>
    )
  }
}
