import React from 'react'
import PropTypes from 'prop-types'
import Block from './blocks/Block'
import SectionTitle from './SectionTitle'
import styles from './ProjectSection.module.css'

function ProjectSection(props) {
  return (
    <section
      id={props.id}
      className={`${styles.root} ${props.colorVariant === 'dark' ? styles.darker : ''}`}
    >
      <SectionTitle title={props.title}></SectionTitle>

      {props.content.map(contentBlock => (
        <div className={styles.block} key={contentBlock._key}>
          <Block
            type={contentBlock._type}
            data={contentBlock}
            openVideoPlayer={props.openVideoPlayer}
          />
        </div>
      ))}
    </section>
  )
}

ProjectSection.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.array,
  openVideoPlayer: PropTypes.func,
  colorVariant: PropTypes.oneOf(['dark', 'light'])
}

export default ProjectSection
