import React from 'react'
import PropTypes from 'prop-types'
import Container from '../../Container'
import styles from './Video.module.css'
import Img from 'gatsby-image'
import { getFixedGatsbyImage } from 'gatsby-source-sanity'
import { FaPlay } from 'react-icons/fa'

function Video(props) {
  const sanityConfig = {
    projectId: 'oslxrhwr',
    dataset: 'production'
  }
  return (
    <div className={styles.wrapper}>
      <div>
        <Container verticalPadding={false}>
          <div className={styles.root}>
            <div className={styles.preview}>
              {props.cover && (
                <Img
                  loading="eager"
                  fixed={getFixedGatsbyImage(props.cover.asset, { width: 1600 }, sanityConfig)}
                  style={{ width: '100%', maxHeight: '100%' }}
                />
              )}
              <div
                className={styles.playButton}
                onClick={() => props.openVideoPlayer(props.url)}
                role="button"
              >
                <FaPlay />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

Video.propTypes = {
  url: PropTypes.string.isRequired,
  cover: PropTypes.object,
  openVideoPlayer: PropTypes.func.isRequired
}

export default Video
