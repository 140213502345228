import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Player from '@vimeo/player'

let uniqueId = 0

export default class vimeoPlayer extends Component {
  // Based on: https://github.com/vimeo/player.js/
  static propTypes = {
    videoId: PropTypes.number.isRequired,
    closeVideoHandler: PropTypes.func.isRequired,
    shouldBePlaying: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      playerHolderId: `video-holder-${uniqueId++}`,
      isPlaying: false,
      isLoaded: false
    }

    this.containerRef = null
  }

  componentWillUnmount() {
    // Should we unload video? Stop it?
  }

  componentDidMount() {
    const videoProps = {
      background: false,
      controls: true,
      byline: false,
      title: false,
      width: this.containerRef.offsetWidth,

    };
    videoProps.url = this.props.videoUrl ? this.props.videoUrl : this.props.videoId;

    this.player = new Player(this.state.playerHolderId, videoProps)
    this.player.on('ended', this.onVideoEnd)
    this.player.on('play', this.onVideoPlay)
    this.player.on('pause', this.onVideoPause)
    if (this.props.shouldBePlaying) this.player.play()
    this.setState({ isLoaded: true })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shouldBePlaying !== this.props.shouldBePlaying) {
      if (!this.state.isPlaying) {
        this.player.play()
        this.setState({ isPlaying: true })
      } else {
        this.player.pause()
        this.setState({ isPlaying: false })
      }
    }
  }

  onVideoPlay = () => {
    this.setState({ isPlaying: true })
  }

  onVideoPause = () => {
    this.setState({ isPlaying: false })
  }

  onVideoEnd = () => {
    this.setState({ isPlaying: false })
    this.props.closeVideoHandler()
  }

  render() {
    return <div id={this.state.playerHolderId} ref={div => (this.containerRef = div)}></div>
  }
}
