import React from 'react'
import PropTypes from 'prop-types'
import { getFixedGatsbyImage } from 'gatsby-source-sanity'
import Img from 'gatsby-image'
import Container from '../../../Container'
import FloatingImages from '../FloatingImages'
import styles from './ImageContent.module.css'
import BlockContent from '../../../block-content/index'

function Content(content, position) {
  if (!content) return null
  return (
    <div className={`${styles.content} ${styles[position]}`} key="content">
      <BlockContent blocks={content} />
    </div>
  )
}

/**
 * Retrieves fixed images that can be displayed with gatsby-image
 */
function getFixedImages(images) {
  const sanityConfig = {
    projectId: 'oslxrhwr',
    dataset: 'production'
  }
  const fixedImages = []
  // If we have more then 1 image we will display Floating images module which does not require full width.
  const width = images.length > 1 ? 600 : 900
  images.forEach(image => {
    fixedImages.push(getFixedGatsbyImage(image.asset, { width }, sanityConfig))
  })
  return fixedImages
}

function Image(layout, images) {
  const fixedImages = getFixedImages(images)
  return (
    <div key="image" className={styles.imageWrap} style={{maxHeight: "450px"}}>
      <div
        className={`${styles.img} 
      ${styles[layout]} 
      ${fixedImages.length > 1 ? styles.multiple : ''}`}
      >
        {fixedImages.length === 1 ? (
          <Img
            loading="eager"
            fixed={fixedImages[0]}
            alt={images[0].alt || ''}
            style={{ maxHeight: '100%', width: '100%', height: '100%' }}
            placeholderStyle={{ position: 'absolute', top: -5 }}
            imgStyle={{ objectFit: 'contain', position: 'relative' }}
          />
        ) : (
          <FloatingImages images={fixedImages} />
        )}
      </div>
    </div>
  )
}

function imageContent(props) {
  const { layout, content, images } = props

  return (
    <Container verticalPadding={false} propStyles={{ height: '100%' }}>
      <div className={`${styles.root}`}>
        {props.images &&
          props.content &&
          props.layout === 'imageLeft' && [Image(layout, images), Content(content, 'contentRight')]}
        {props.images &&
          props.content &&
          props.layout === 'imageRight' && [Content(content, 'contentLeft'), Image(layout, images)]}

        {!props.images && props.layout === 'imageRight' && Content(content, 'contentLeft')}

        {!props.images && props.layout === 'imageLeft' && Content(content, 'contentRight')}
      </div>
    </Container>
  )
}

imageContent.propTypes = {
  layout: PropTypes.oneOf(['imageLeft', 'imageRight']),
  content: PropTypes.array,
  images: PropTypes.array
}

imageContent.defaultProps = {
  content: [],
  layout: 'imageRight'
}

export default imageContent
