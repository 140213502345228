import React, { useContext, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import gsap, { Power2 } from 'gsap'
import { useInView } from 'react-intersection-observer'
import SanityImage from '../../SanityImage'
import BlockContent from '../../block-content'
import styles from './Results.module.css'
import Container from '../../Container'
import { GlobalStateContext } from '../../../containers/GlobalContextProvider'
function Results(props) {
  const [hasAnimatedNumbers, setAnimatedNumbers] = useState(false)
  const global = useContext(GlobalStateContext)
  const wrapRef = useRef(null)

  const [ref, inView] = useInView({ threshold: 0.33 })

  useEffect(() => {
    if (inView && !hasAnimatedNumbers) {
      triggerNumberAnimations()
    }
  }, [inView])

  const triggerNumberAnimations = () => {
    const outcomes = wrapRef.current.querySelectorAll('.result-emphasis')
    const delayBetweenAnimations = 0.5

    for (let i = 0; i < outcomes.length; i++) {
      const outcome = outcomes[i]
      const { symbol, targetnumber } = outcome.dataset

      const animate = { number: 0 }
      gsap.to(animate, 2, {
        number: targetnumber,
        ease: Power2.easeOut,
        delay: i * delayBetweenAnimations,
        onUpdate: () => {
          outcome.innerHTML = `${new Intl.NumberFormat(global.locale, {
            maximumSignificantDigits: 2
          }).format(animate.number)} ${symbol || ''}`
        }
      })
    }

    setAnimatedNumbers(true)
  }

  return (
    // We loop through results array. Result wrapper will be flexed container. Each result represents 50% of container width
    // Content block will provide custom serializer for "resultEmphasis" content block type. This is custom block that we defined in Sanity.
    // We will render custom HTML for this block. Than we will style it and reference it with JS to animate numbers in that block.
    // Also display Icon
    <Container verticalPadding={false} propStyles={{ height: '100%' }}>
      <div className={styles.wrap} ref={ref}>
        <div className={styles.root} ref={wrapRef}>
          {props.results.map(result => (
            <div className={styles.result} key={result._key}>
              {result.icon && (
                <div className={styles.icon}>
                  <SanityImage image={result.icon} width={100} />
                </div>
              )}
              <div className={styles.content}>
                <BlockContent blocks={result.content} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}

Results.propTypes = {
  results: PropTypes.array.isRequired
}

export default Results
