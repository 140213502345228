import React from 'react'
import PropTypes from 'prop-types'
import styles from './RichText.module.css'
import Container from '../../Container'
import BlockContent from '../../block-content'

function ContentBlock(props) {
  return (
    <Container>
      <div className={styles.root}>
        <div className={(styles.content, styles[props.position])}>
          <BlockContent blocks={props.content}></BlockContent>
        </div>
      </div>
    </Container>
  )
}

ContentBlock.propTypes = {
  content: PropTypes.array.isRequired,
  position: PropTypes.oneOf(['centered', 'left', 'right', 'fullWidth'])
}

export default ContentBlock
