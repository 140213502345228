import React from 'react'
import Container from '../../Container'

export default function BlockNotFound(props) {
  return (
    <Container>
      <div
        className="error"
        style={{ textAlign: 'center', padding: '40px', border: '1px solid red' }}
      >
        <h3>Content block type "{props.type}" not found.</h3>
        <p>Make sure you made React component and mapped it in Block.js</p>
      </div>
    </Container>
  )
}
