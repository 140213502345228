import React from 'react'
import PropTypes from 'prop-types'
import ImageContent from './imageContent/ImageContent'
import Results from './Results'
import BlockNotFound from './BlockNotFound'
import RichText from './RichText'
import FullImage from './FullImage'
import Images from './Images'
import Video from './Video'

function Block({ data, type, openVideoPlayer }) {
  // Map sanity object type names to react components
  switch (type) {
    case 'imageContent':
      return <ImageContent {...data}></ImageContent>
    case 'results':
      return <Results {...data}></Results>
    case 'richText':
      return <RichText {...data}></RichText>
    case 'fullImage':
      return <FullImage {...data}></FullImage>
    case 'images':
      return <Images {...data}></Images>
    case 'video':
      return <Video {...data} openVideoPlayer={openVideoPlayer}></Video>
    default:
      return <BlockNotFound type={type}></BlockNotFound>
  }
}

Block.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  openVideoPlayer: PropTypes.func.isRequired
}

export default Block
