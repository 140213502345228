import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import VideoPlayer from './VideoPlayer'
import ProjectHero from './ProjectHero'
import { slugify } from '../../lib/helpers'
import { GlobalStateContext, GlobalDispatchContext } from '../../containers/GlobalContextProvider'
import ProjectSection from './ProjectSection'
import NextPageTrigger from '../NextPageTrigger'
function Project(props) {
  // Move video to Context?
  const [video, toggleVideo] = useState({ url: '', opened: false })
  const context = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const [pageReady, setPageReady] = useState(false)
  const [heroReady, setHeroReady] = useState(false)
  const [fontsReady, setFontsReady] = useState(false)

  const { fontsLoaded } = context
  useEffect(() => {
    if (fontsLoaded) setFontsReady(true)
    if (fontsReady && heroReady) onPageReady()

    return () => {
      setPageReady(false)
      setHeroReady(false)
    }
  }, [fontsLoaded, fontsReady, heroReady, pageReady])

  useEffect(() => {
    if (video.opened) {
      dispatch({ type: 'TOGGLE_HEADER', shown: false })
    } else {
      dispatch({ type: 'TOGGLE_HEADER', shown: true })
    }
  }, [video])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onScroll)
    }
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  let header = 'light'
  function onScroll() {
    if (window.scrollY > window.innerHeight) {
      if (header !== 'dark') {
        dispatch({ type: 'SET_HEADER_VARIATION', variation: 'darkGradient' })
        header = 'dark'
      }
    } else {
      if (header !== 'light') {
        dispatch({ type: 'SET_HEADER_VARIATION', variation: 'light' })
        header = 'light'
      }
    }
  }

  function onPageReady() {
    dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageEntering' })
    setPageReady(true)
  }

  return (
    <div className="singleProjectPageWrap">
      {props.mainImage && (
        <ProjectHero
          mainImage={props.mainImage}
          title={props.title}
          client={props.client}
          caseVideoUrl={props.video}
          onImageLoaded={() => setHeroReady(true)}
          openVideoPlayer={url => toggleVideo({ opened: true, url })}
          animate={pageReady}
        />
      )}

      {props._rawSections?.map(section => {
        return (
          section.title && (
            <ProjectSection
              key={section._key}
              id={`${slugify(section.title)}`}
              content={section.content}
              title={section.title}
              colorVariant={section.colorVariant}
              openVideoPlayer={url => toggleVideo({opened: true, url})}
              />
          )
        )
      })}

      <VideoPlayer
        opened={video.opened}
        url={video.url}
        closeHandler={() => toggleVideo({ opened: false })}
      />

      <NextPageTrigger
        position="relative"
        addExtraHeight
        shown
        enabled
        zIndex={3}
        link={context.locale === 'sl' ? '/projekti' : '/en/projects'}
        title={context.locale === 'sl' ? 'Projekti' : 'Projects'}
        beforePageChange={() => dispatch({ type: 'SCROLL_TO_PROJECT_LIST', scrollToList: true })}
      ></NextPageTrigger>
    </div>
  )
}

Project.propTypes = {
  mainImage: PropTypes.object.isRequired,
  _rawSections: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  video: PropTypes.string,
  tagline: PropTypes.string
}

export default Project
