import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import Container from '../components/Container'
import GraphQLErrorList from '../components/graphql-error-list'
import Project from '../components/project/Project'
import SEO from '../components/seo'
import { createLocaleTextGetter } from '../lib/localizor'
import { GlobalDispatchContext } from '../containers/GlobalContextProvider'

export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      publishedAt
      title {
        _type
        sl
        en
      }
      slug {
        _type
        en {
          current
        }
        sl {
          current
        }
      }
      tagline {
        _type
        sl
        en
      }
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
          fluid(maxWidth: 2800) {
            ...GatsbySanityImageFluid_noBase64
          }
          metadata {
            lqip
          }
        }
      }
      video {
        _type
        en
        sl
      }
      client
      _rawSections(resolveReferences: { maxDepth: 10 })
    }
  }
`

// We can not wrap this component into localized HOC.
// I suspect this is because this component gets initialized while gatsby is building sites dynamically
// Thus we will just call translator manually with project data and pass localised data to Project

const ProjectTemplate = props => {
  const { data, errors } = props
  const getLocalizedContent = createLocaleTextGetter(props.pageContext.locale)
  const project = getLocalizedContent(data.project)
  const dispatch = useContext(GlobalDispatchContext)

  useEffect(() => {
    dispatch({
      type: 'SET_PAGE_CONTEXT_DATA',
      data: { ...props.pageContext, layout: 'normal' }
    })
  }, [])

  return (
    <>
      {errors && <SEO title="GraphQL Error" />}
      {project && <SEO title={project.title || 'Untitled'} description={project.description} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {project && <Project {...project} />}
    </>
  )
}

export default ProjectTemplate
