import React from 'react'
import PropTypes from 'prop-types'
import styles from './Images.module.css'
import Container from '../../Container'
import FloatingImages from './FloatingImages'

function Images (props) {
  let imageWidth
  const { images } = props
  // Full container width
  if (images.length === 1) imageWidth = 1200
  // A little more than half container
  else if (images.length === 2) imageWidth = 800
  else imageWidth = 600 // More than third of container width

  return (
    <Container>
      <div className={styles.root}>
        <FloatingImages images={props.images} />
      </div>
    </Container>
  )
}

Images.propTypes = {
  images: PropTypes.array.isRequired
}

export default Images
