import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './ProjectHero.module.css'
import playVideoIcon from '../../images/play-case-video.svg'
import gsap from 'gsap'
import Img from 'gatsby-image'
import { GlobalStateContext } from '../../containers/GlobalContextProvider'

class ProjectHero extends Component {
  static contextType = GlobalStateContext
  static defaultProps = {
    title: '',
    client: ''
  }

  static propTypes = {
    mainImage: PropTypes.object.isRequired,
    title: PropTypes.string,
    client: PropTypes.string,
    caseVideoUrl: PropTypes.string,
    openVideoPlayer: PropTypes.func,
    startAnimations: PropTypes.bool,
    onImageLoaded: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {}
    this.heroImageLoaded = false
    this.titleEl = null
    this.clientEl = null
    this.caseVideoEl = null
  }

  componentDidMount() {
    this.titleTween = gsap
      .fromTo(this.titleEl, { opacity: 0, y: 60 }, { y: 0, opacity: 1, duration: 1.2 })
      .paused(true)

    if (this.clientEl) {
      this.clientTween = gsap
        .fromTo(this.clientEl, { opacity: 0, y: 40 }, { y: 0, opacity: 1, duration: 1, delay: 0.6 })
        .paused(true)
    }

    if (this.caseVideoEl) {
      this.caseVideoTween = gsap
        .fromTo(
          this.caseVideoEl,
          { opacity: 0, y: 20 },
          { opacity: 1, y: 0, duration: 0.6, delay: 1 }
        )
        .paused(true)
    }
  }

  componentDidUpdate() {
    if (this.context.fontsLoaded && this.heroImageLoaded) this.startAnimations()
  }

  componentWillUnmount() {
    this.heroImageLoaded = false
  }

  startAnimations() {
    this.titleTween && this.titleTween.play()
    this.imageTween && this.imageTween.play()
    this.clientTween && this.clientTween.play()
    this.caseVideoTween && this.caseVideoTween.play()
  }

  onHeroImageLoaded = () => {
    // Should start animations or remove page transition overlay once hero image is loaded
    if (this.props.onImageLoaded) this.props.onImageLoaded()
    this.heroImageLoaded = true
    if (this.context.fontsLoaded && !this.animated) this.startAnimations()
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={styles.heroImage}>
          <Img
            loading="eager"
            fluid={this.props.mainImage.asset.fluid}
            onLoad={this.onHeroImageLoaded.bind(this)}
            style={{ height: '100%' }}
          />
        </div>

        <div className={styles.mainContent}>
          <h1 ref={div => (this.titleEl = div)} className={styles.mainTitle}>
            {this.props.title}
          </h1>
          <div className={styles.client} ref={div => (this.clientEl = div)}>
            {this.props.client}
          </div>
        </div>

        {this.props.caseVideoUrl && (
          <div
            className={`${styles.videoTrigger} ${
              !this.context.viewport.isFullHeight ? styles.extraBottom : ''
            }`}
            ref={div => (this.caseVideoEl = div)}
          >
            <div
              className={styles.videoTriggerInner}
              onClick={() => this.props.openVideoPlayer(this.props.caseVideoUrl)}
              role="button"
            >
              <div>case</div>
              <div>
                <img src={playVideoIcon} alt="Play case video" />
              </div>
              <div>video</div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ProjectHero
