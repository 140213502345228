import React from 'react'
import PropTypes from 'prop-types'
import styles from './SectionTitle.module.css'
import Container from '../Container'

function SectionTitle (props) {
  return (
    <Container verticalPadding={false}>
      <div className={styles.root}>
        <h2 className={styles.title}>{props.title}</h2>
        <div className={styles.line} />
      </div>
    </Container>
  )
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default SectionTitle
