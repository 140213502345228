import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styles from './FloatingImages.module.css'

export default class FloatingImages extends Component {
  static propTypes = {
    images: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      activeImage: 0
    }
  }

  render() {
    return (
      <div className={styles.root} data-images={this.props.images.length}>
        <div className={styles.floatingWrapper}>
          {this.props.images.map((image, index) => (
            <div
              key={index}
              className={`${styles.image} ${index === this.state.activeImage ? styles.active : ''}`}
              onClick={() => this.setState({ activeImage: index })}
              role="button"
            >
              <Img
                fixed={image}
                alt={image.alt || ''}
                style={{ width: '100%', maxHeight: '100%', overflow: 'visible' }}
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }
}
