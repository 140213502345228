import React from 'react'
import PropTypes from 'prop-types'
import SanityImage from '../../SanityImage'
import Container from '../../Container'
import styles from './FullImage.module.css'

function FullImage(props) {
  return (
    <Container>
      <div className={styles.root}>
        <SanityImage
          image={{ asset: props.asset }}
          width={1200}
          fit="clip"
          loading="lazy"
        ></SanityImage>
      </div>
    </Container>
  )
}

FullImage.propTypes = {
  asset: PropTypes.object.isRequired,
  alt: PropTypes.string
}

export default FullImage
